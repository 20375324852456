import React from 'react';
import { CheckboxInterface } from '@components/Checkbox/interfaces';
import MuiCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const Checkbox: React.FC<CheckboxInterface> = ({
  label,
  checked,
  onChange,
  name,
  required = false,
  error = false,
  transparent,
}) => {
  return (
    <FormControl error={error}>
      <FormControlLabel
        required={required}
        sx={{
          color: error ? 'error.main' : transparent ? 'white' : 'primary.main',
        }}
        control={
          <MuiCheckbox
            checked={checked}
            onChange={onChange}
            name={name}
            sx={
              transparent
                ? {
                    color: error ? 'error.main' : 'white',
                    '&.Mui-checked': {
                      color: 'white',
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.08)',
                    },
                    '& .MuiSvgIcon-root': {
                      fill: 'white',
                    },
                  }
                : {}
            }
          />
        }
        label={label}
      />
    </FormControl>
  );
};

export default Checkbox;
