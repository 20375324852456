export const white = {
  '& .MuiInputBase-input': {
    color: 'white',
    backgroundColor: 'black',
    '&:-webkit-autofill': {
      boxShadow: '0 0 0px 1000px black inset',
      WebkitTextFillColor: 'white',
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'white!important',
  },
};
