import React from 'react';
import {
  AbsoluteBox,
  AccordionTitle,
  BigPoster,
  ButtonWrapper,
  Content,
  Copyright,
  CopyrightWrapper,
  Footer,
  FortunaBet,
  GridCenter,
  Header,
  HeaderImage,
  Href,
  ImagePoster,
  LogoGromda,
  Logotype,
  Logotypes,
  Paragraph,
  Section,
  StyledLink,
  Text,
  Title,
} from '@styles/layoutGromda.styles';
import Container from '@mui/material/Container';
import Legal from '@components/Legal';
import Grid from '@mui/material/Grid';
import Opinion from '@components/Opinion';
import Button from '@components/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoAndLogo from '@components/LogoAndLogo';
import FightersGroup from '@components/FightersGroup';
import { useMediaQuery, useTheme } from '@mui/material';
import { handleOpenPdf } from '@utils/helpers';

const LayoutGromda = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleScroll = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <Content>
      <Section color="#000">
        <Container
          sx={{
            padding: {
              xs: 0,
            },
          }}
        >
          <Header>
            <HeaderImage src="/images/gromda/header.jpg" />
            <LogoAndLogo
              firstLogo="/images/logotypes/gromda19.png"
              secondLogo="/images/logotypes/fortuna.png"
            />
          </Header>
        </Container>
      </Section>

      <Section color="#000000" isPaddingBottom id="form" padding="0 40px">
        <Container>
          <Title isCenter>Kampania dobiegła końca</Title>
        </Container>
      </Section>

      <Section color="#000000" isPaddingBottom>
        <Container>
          <FortunaBet src="/images/logotypes/fortuna-bet.png" />
        </Container>
      </Section>

      <Section>
        <Container
          sx={{
            padding: {
              xs: 0,
            },
          }}
        >
          <FightersGroup />
        </Container>
      </Section>

      <Section>
        <Container
          sx={{
            padding: {
              xs: 0,
            },
          }}
        >
          <BigPoster>
            {isMobile ? (
              <ImagePoster src="/images/background/gromda-mobile.jpg" />
            ) : (
              <ImagePoster src="/images/background/gromda.jpg" />
            )}

            <AbsoluteBox>
              <LogoGromda height={88} src="/images/logotypes/gromda-big.png" />
              <ButtonWrapper isCenter>
                <Button
                  text="Odbierz darmowy dostęp do PPV"
                  color="error"
                  onClick={() => handleScroll('form')}
                />
              </ButtonWrapper>
            </AbsoluteBox>
          </BigPoster>
        </Container>
      </Section>

      <Section color="#F8F9FF" isPaddingBottom isPaddingTop>
        <Container>
          <Legal />

          <Grid
            container
            spacing={2}
            sx={{
              marginTop: {
                xs: 0,
                md: '40px',
              },
            }}
          >
            <Grid item xs={12} md={4}>
              <Opinion text="Nie spodziewałem się, że to będzie takie proste. Kod PPV działał od razu, a ja obejrzałem świetną galę KSW 100 za friko. Mega fajna akcja, oby więcej takich!" />
            </Grid>
            <Grid item xs={12} md={4}>
              <Opinion text="Super sprawa! Kod PPV dostałem w kilka minut i działał bez problemu. Za darmo obejrzałem walkę, na którą czekałem od dawna. Polecam każdemu!" />
            </Grid>
            <Grid item xs={12} md={4}>
              <Opinion text="Kod dostałam szybko. Dzięki za możliwość, mam nadzieję, że to nie ostatni raz!" />
            </Grid>
          </Grid>
        </Container>
      </Section>

      <Section isPaddingBottom isPaddingTop>
        <Container>
          <Grid container spacing={'80px'}>
            <Grid item xs={12} md={6}>
              <GridCenter>
                <Title color="#000" fontSize={48} margin={24}>
                  GROMDA 19: PORACHUNKI już w PIĄTEK 13 grudnia!
                </Title>
                <Paragraph center>
                  Najlepsza federacja walk na gołe pięści. Elitarny klub
                  dżentelmenów GROMDA. Podziemne stowarzyszenie prawdziwych
                  mężczyzn skupia w swoich szeregach niepodrabialnych
                  wojowników.
                </Paragraph>
                <Paragraph center>
                  Boks powraca do korzeni, a fani spragnieni prawdziwych walk na
                  gołe pięści, z poszanowaniem honorowych zasad czekali na to od
                  zawsze. GROMDA wchodzi do gry!
                </Paragraph>
                <Paragraph center>
                  Brutalne i krwawe starcia współczesnych wojowników! Czas
                  poznać kto jest największym sk*rwielem w tej grze. Kto ma
                  charakter, jaja ze stali i trochę szczęścia. GROMDA: Nie ma
                  miękkiej gry.
                </Paragraph>

                <ButtonWrapper>
                  <Button
                    text="Zarejestruj się"
                    color="error"
                    size="large"
                    onClick={() => handleScroll('form')}
                  />
                </ButtonWrapper>
              </GridCenter>
            </Grid>

            <Grid item xs={12} md={6}>
              <ImagePoster src="/images/gromda/gromda-poster.jpg" />
            </Grid>
          </Grid>
        </Container>
      </Section>

      <Section isPaddingBottom isPaddingTop>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Title color="#000" fontSize={48} mobileFontSize={48} margin={24}>
                FAQs
              </Title>
            </Grid>
            <Grid item xs={12} md={7}>
              <Accordion sx={{ boxShadow: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <AccordionTitle>Jakie są warunki promocji ?</AccordionTitle>
                </AccordionSummary>
                <AccordionDetails>
                  <Paragraph fontSize={16} margin="0">
                    Promocja obowiązuje wyłącznie dla nowych klientów Fortuny.
                  </Paragraph>
                  <Paragraph fontSize={16} margin="0 0 12px 0">
                    Aby skorzystać z oferty wykonaj poniższe działania:
                  </Paragraph>
                  <Paragraph fontSize={16} margin="0">
                    1. Wpisz dane w naszym serwisie - otrzymasz mail z linkiem
                    promocyjnym.
                  </Paragraph>
                  <Paragraph fontSize={16} margin="0">
                    2. Zarejestruj się w Fortunie, z linku który otrzymasz w
                    mailu.
                  </Paragraph>
                  <Paragraph fontSize={16} margin="0">
                    3. Zweryfikuj konto z wykorzystaniem profilu zaufanego lub
                    konta bankowego.
                  </Paragraph>
                  <Paragraph fontSize={16} margin="0 0 12px 0">
                    4. Wpłać 20 zł pierwszego depozytu i obstaw 2 walki z
                    wydarzenia Gromda 19.
                  </Paragraph>
                  <Paragraph fontSize={16} margin="0">
                    System zweryfikuje Twoją rejestrację i prześle Ci kod PPV na
                    adres e-mail.
                  </Paragraph>
                  <Paragraph fontSize={16} margin="0">
                    Warunkiem odbioru kodu, jest spełnienie wszystkich warunków
                    promocji.
                  </Paragraph>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ boxShadow: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <AccordionTitle>Kiedy dostanę kod ?</AccordionTitle>
                </AccordionSummary>
                <AccordionDetails>
                  <Paragraph fontSize={16} margin="0">
                    Kod otrzymasz w ciągu 2 godzin, po spełnieniu warunków
                    promocji.
                  </Paragraph>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ boxShadow: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <AccordionTitle>
                    Czy promocja jest wyłącznie dla nowych klientów Fortuny ?
                  </AccordionTitle>
                </AccordionSummary>
                <AccordionDetails>
                  <Paragraph fontSize={16} margin="0">
                    Tak, promocja jest wyłącznie dla nowych klientów Fortuny,
                    którzy zweryfikowali swoje konto.
                  </Paragraph>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ boxShadow: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <AccordionTitle>
                    Czy muszę zweryfikować konto ?
                  </AccordionTitle>
                </AccordionSummary>
                <AccordionDetails>
                  <Paragraph fontSize={16} margin="0">
                    Tak, musisz zweryfikować konto w Fortunie, zajmie Ci to 3
                    minuty.
                  </Paragraph>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ boxShadow: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <AccordionTitle>
                    W jaki sposób dostanę kod PPV ?
                  </AccordionTitle>
                </AccordionSummary>
                <AccordionDetails>
                  <Paragraph fontSize={16} margin="0">
                    Kod otrzymasz w wiadomości e-mail, po spełnieniu warunków
                    promocji. Kod PPV zrealizujesz na stronie{' '}
                    <Href
                      href="https://gromda.tv/#/redeem-code"
                      target="_blank"
                    >
                      https://gromda.tv/#/redeem-code
                    </Href>
                    {'. '}
                    Po poprawnym zalogowaniu się na platformie GROMDA.tv możesz
                    zrealizować kod wpisując go w polu formularza{' '}
                    {'"Zrealizuj kod"'}
                  </Paragraph>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Container>
      </Section>

      <Footer>
        <Container>
          <Logotypes>
            <Logotype src="/images/logotypes/gromda.png" />
            <Logotype src="/images/logotypes/fortuna.png" />
          </Logotypes>
        </Container>
      </Footer>

      <Copyright>
        <Container>
          <CopyrightWrapper>
            <Text>© 2024 Watchcode. All rights reserved.</Text>
            <StyledLink
              fontSize="14px"
              fontFamily='"Reddit Sans", sans-serif'
              onClick={() => handleOpenPdf('polityka-prywatnosci')}
            >
              Polityka prywatności
            </StyledLink>
            <StyledLink
              fontSize="14px"
              fontFamily='"Reddit Sans", sans-serif'
              onClick={() => handleOpenPdf('regulamin')}
            >
              Regulamin
            </StyledLink>
            <StyledLink
              fontSize="14px"
              fontFamily='"Reddit Sans", sans-serif'
              onClick={() => handleOpenPdf('regulamin-promocji')}
            >
              Regulamin promocji
            </StyledLink>
          </CopyrightWrapper>
        </Container>
      </Copyright>
    </Content>
  );
};

export default LayoutGromda;
