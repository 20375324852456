import React from 'react';
import { Text, Wrapper } from '@components/Opinion/styles';
import Rating from '@mui/material/Rating';

interface Props {
  text: string;
}

const Opinion: React.FC<Props> = ({ text }) => {
  return (
    <Wrapper>
      <Rating
        name="read-only"
        value={5}
        readOnly
        sx={{ marginBottom: '24px' }}
      />

      <Text>
        {'"'}
        {text}
        {'"'}
      </Text>
    </Wrapper>
  );
};

export default Opinion;
