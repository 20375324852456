import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  width: 100%;
`;

export const Image = styled.img`
  display: block;
  width: 176px;
  height: 176px;
  mix-blend-mode: multiply;
`;

export const Box = styled.div`
  display: flex;
  flex-flow: wrap column;
  gap: 12px;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 40px 10px;
  }
`;

export const Title = styled.h3`
  font-family:
    Chakra Petch,
    sans-serif;
  font-size: 48px;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 12px;

  @media (max-width: 768px) {
    font-size: 32px;
    text-align: center;
  }
`;

export const Subtitle = styled.p`
  font-family:
    Chakra Petch,
    sans-serif;
  font-size: 18px;
  margin-top: 0;
  text-align: left;
  margin-bottom: 12px;

  @media (max-width: 768px) {
    text-align: center;
  }
`;
