import React from 'react';
import { Wrapper, Image, Box } from '@components/FightersGroup/styles';

const FightersGroup = () => {
  return (
    <Wrapper>
      <Box>
        <Image src="/images/fighters/fizol.jpg" />
      </Box>
      <Box>
        <Image src="/images/fighters/slomka.jpg" />
      </Box>
      <Box>
        <Image src="/images/fighters/vasyl.jpg" />
      </Box>
      <Box>
        <Image src="/images/fighters/balboa.jpg" />
      </Box>
      <Box>
        <Image src="/images/fighters/gorilla.jpg" />
      </Box>
      <Box>
        <Image src="/images/fighters/joker.jpg" />
      </Box>
      <Box>
        <Image src="/images/fighters/bombardier.jpg" />
      </Box>
    </Wrapper>
  );
};

export default FightersGroup;
