import { createTheme } from '@mui/material/styles';

let theme = createTheme({});

theme = createTheme({
  palette: {
    primary: {
      main: '#2D314A',
    },
    secondary: {
      main: '#dc004e',
    },
    error: {
      main: '#d32f2f',
      light: '#d32f2f',
      dark: '#d32f2f',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h1: {
      fontFamily: 'Chakra Petch, sans-serif',
      fontWeight: '700',
    },
    h2: {
      fontFamily: 'Chakra Petch, sans-serif',
      fontWeight: '700',
    },
    h3: {
      fontFamily: 'Chakra Petch, sans-serif',
      fontWeight: '700',
    },
    h4: {
      fontFamily: 'Chakra Petch, sans-serif',
      fontWeight: '700',
    },
    h5: {
      fontFamily: 'Chakra Petch, sans-serif',
      fontWeight: '700',
    },
    h6: {
      fontFamily: 'Chakra Petch, sans-serif',
      fontWeight: '500',
    },
    body1: {
      fontFamily: 'Montserrat, sans-serif',
    },
    body2: {
      fontFamily: 'Montserrat, sans-serif',
    },
    subtitle1: {
      fontFamily: 'Montserrat, sans-serif',
    },
    subtitle2: {
      fontFamily: 'Montserrat, sans-serif',
    },
    caption: {
      display: 'block',
      fontFamily: 'Montserrat, sans-serif',
      textTransform: 'uppercase',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1488,
      xl: 1920,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: 'rgb(250,250,250)',
          minHeight: '100vh',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontFamily: 'Chakra Petch, sans-serif',
          fontWeight: '600',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            pointerEvents: 'none',
          },
        },
      },
    },
  },
});

export default theme;
