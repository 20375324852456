import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  height: 232px;
  overflow: hidden;

  & > * {
    flex: 0 0 calc(100% / 7);
  }

  @media (max-width: 768px) {
    height: auto;

    & > * {
      flex: 0 0 calc(100% / 2);
    }

    & > *:last-child {
      display: none;
    }
  }
`;

export const Image = styled.img`
  display: flex;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  object-fit: cover;
  object-position: center;
`;

export const Box = styled.div`
  display: block;
  overflow: hidden;
`;
