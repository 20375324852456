import React from 'react';
import { Image, Wrapper, Title, Subtitle, Box } from '@components/Legal/styles';

const Legal = () => {
  return (
    <Wrapper>
      <Image src="/images/gromda/legal.png" />
      <Box>
        <Title>100% legalnie. Bez ściemy!</Title>
        <Subtitle>Dołącz do grona zadowolonych klientów</Subtitle>
      </Box>
    </Wrapper>
  );
};

export default Legal;
