import React from 'react';
import { And, Logo1, Logo2, Wrapper } from '@components/LogoAndLogo/styles';

interface Props {
  firstLogo: string;
  secondLogo: string;
}

const LogoAndLogo: React.FC<Props> = ({ firstLogo, secondLogo }) => {
  return (
    <Wrapper>
      <Logo1 src={firstLogo} />
      <And>&</And>
      <Logo2 src={secondLogo} />
    </Wrapper>
  );
};

export default LogoAndLogo;
