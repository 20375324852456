import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap column;
  width: 100%;
  padding: 32px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const Text = styled.p`
  margin: 0;
  font-family: 'Reddit Sans', sans-serif;
  font-size: 18px;
  line-height: 1.5;
`;
